<template>
	<div class="notfound">
		Not found 404<br />

		<p v-if="$route.params.category">
			{{ errorText }} {{ $route.params.category }}
		</p>
	</div>
</template>

<script>
	// @ is an alias to /src
	// import HelloWorld from '@/components/HelloWorld.vue';

	export default {
		name: 'notfound',
		props:{
errorText:String

		},

		components: {
			//			HelloWorld,
		},
	};
</script>
